
import { defineComponent, onMounted } from "vue";
import TableWidget9 from "@/components/widgets/table/Widget9.vue";
import TableWidget3 from "@/components/widgets/table/Widget3.vue";
import MixedWidget1 from "@/components/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/components/widgets/list/Widget1.vue";
import ListWidget2 from "@/components/widgets/list/Widget2.vue";
import ListWidget3 from "@/components/widgets/list/Widget3.vue";
import ListWidget5 from "@/components/widgets/list/Widget5.vue";
import ListWidget6 from "@/components/widgets/list/Widget6.vue";
import StatsWidget7 from "@/components/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/components/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/components/widgets/stats/Widget13.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    TableWidget9,
    TableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget2,
    ListWidget3,
    ListWidget5,
    ListWidget6,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  }
});
